import React, {useContext, useEffect, useState} from 'react';
import Container from "../container";
import s from './styles.module.css'
import Button from "../../common/button";
import {ReactComponent as Logo} from "../../assets/svg/logo_v2.svg";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import {ReactComponent as Like} from "../../assets/svg/favorite.svg";
import {ReactComponent as Verified} from "../../assets/svg/verified.svg";
import {ReactComponent as Gavel} from "../../assets/svg/gavel.svg";
import {ReactComponent as NavigateProfile} from "../../assets/svg/navigate_profile.svg";
import {ReactComponent as Close} from "../../assets/svg/close.svg";
import {ReactComponent as MonitorIcon} from "../../assets/svg/monitor.svg";
import {ReactComponent as MobileIcon} from "../../assets/svg/mobile.svg";
import {PATH} from "../../constants/path";
import Auth from "../../pages/auth";
import {AuthContext} from "../../App";
import {Avatar, Tooltip, useMediaQuery} from "@mui/material";
import ActiveLots from "./active_lots";
import classNames from "classnames";
import HeaderBurgerLeft from "./header_burger_left";
import {useLogoutQueryMutation} from "../../redux/auth.service";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../redux/slice/user.slice";
import {useGetActiveAuctionsQuery} from "../../redux/active_auctions.service";
import {selectActiveLots} from "../../redux/slice/active_lots.slice";
import {useGetFavoriteQuery} from "../../redux/favority.service";
import {selectFavorites} from "../../redux/slice/favorite.slice";
import {auctionsApi} from "../../redux/auctions.service";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import {setIsAuth} from "../../redux/slice/global.slice";

import {motion} from "framer-motion";
import moment from "moment/moment";

export const path = [
    {
        title: 'Профиль', path: PATH.ACCOUNT,
    },
    {
        title: 'Настройки', path: PATH.ACCOUNT + '/' + PATH.PROFILE,
    },
    {
        title: 'Кошелёк', path: PATH.ACCOUNT + '/' + PATH.WALLET,
    },
    {
        title: 'Мои лоты', path: PATH.ACCOUNT + '/' + PATH.MY_LOTS,
    },
    {
        title: 'Избранное', path: PATH.ACCOUNT + '/' + PATH.FAVORITES,
    },
    {
        title: 'Реферальная программа', path: PATH.ACCOUNT + '/' + PATH.REFERAL,
    },
    {
        title: 'Календарь событий', path: PATH.ACCOUNT + '/' + PATH.CALENDAR_EVENTS,
    }
]

const AnimatedHeaderCircle = ({
                                  free_bid,
                                  children,
                                  setOpenSelect,
                                  openSelect,
                                  setOpenOpenActiveLots,
                                  type = 1,
                                  onClick
                              }) => {
    if (free_bid) {
        return <motion.div
            onClick={(e) => {
                if (type === 1) {
                    e.stopPropagation();
                    setOpenSelect(!openSelect);
                    setOpenOpenActiveLots(false);
                } else {
                    onClick()
                }

            }}
            className={s.avatar_box_select_main}
            animate={free_bid ? {
                background: [
                    'linear-gradient(0deg, rgba(242, 245, 104, 1) 0%, rgba(37, 253, 29, 1) 100%, rgba(252, 176, 69, 1) 100%)',
                    'linear-gradient(360deg, rgba(242, 245, 104, 1) 0%, rgba(37, 253, 29, 1) 100%, rgba(252, 176, 69, 1) 100%)',
                ],
            } : {}}

            transition={{
                duration: 2, // Длительность анимации
                repeat: free_bid ? Infinity : 0, // Бесконечная анимация
                ease: 'easeInOut',
            }}
        >
            {children}
        </motion.div>
    } else {
        return <div
            onClick={(e) => {
                if (type === 1) {
                    e.stopPropagation();
                    setOpenSelect(!openSelect);
                    setOpenOpenActiveLots(false);
                } else {
                    onClick()
                }
            }}
            className={s.avatar_box_select_main}

        >
            {children}
        </div>
    }
}

const Header = ({isAuth}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useDispatch()
    const {user} = useSelector(selectUser)
    const free_bid = user?.customer_profile?.is_free_bid

    const {data: dataActiveLots} = useGetActiveAuctionsQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        skip: !isAuth
    })
    const {} = useGetFavoriteQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        skip: !isAuth
    })
    const {activeLots} = useSelector(selectActiveLots)
    const {favorites} = useSelector(selectFavorites)

    const query_700 = useMediaQuery('(max-width:780px)');

    const [logoutQuery, {isLoading}] = useLogoutQueryMutation()
    const navigate = useNavigate()

    const [openSelect, setOpenSelect] = useState(false)
    const [openActiveLots, setOpenOpenActiveLots] = useState(false)
    const [openRightMenu, setOpenRightMenu] = useState(false);

    const toggleDrawerRight = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpenRightMenu(open);
    };

    const authModal = useContext(AuthContext)

    const login = (token) => {
    }

    const handleLogout = () => {
        logoutQuery().unwrap().then((res) => {
            dispatch(setIsAuth(false))
            dispatch(auctionsApi.util.resetApiState());
        }).catch((e) => {
            console.log(e)
        })
    }

    useEffect(() => {
        window.addEventListener('click', (e) => {
            setOpenSelect(false)
            setOpenOpenActiveLots(false)
        })

        return () => {
            window.removeEventListener('click', (e) => {
                setOpenSelect(false)
                setOpenOpenActiveLots(false)
            })
        }
    }, [])
    useEffect(() => {
        if (!query_700) {
            toggleDrawerRight(false)
            setOpenRightMenu(false)
        }
    }, [query_700])

    useEffect(() => {
        if (searchParams?.get('registration') === 'open') {
            authModal.actionModal(true)
        }

    }, [searchParams])

    return (
        <div className={s.header_box}>
            {authModal.open && <Auth login={login} open={authModal.open}
                                     onClose={() => authModal.actionModal(false)}/>}
            <Container>
                {isAuth && <HeaderBurgerLeft free_bid={free_bid} handleLogout={handleLogout} type={1}
                                             toggleDrawer={toggleDrawerRight}
                                             openLeftMenu={openRightMenu}/>}

                <div className={s.header}>

                    <div className={s.header_left}>
                        <div className={s.logo_container} onClick={() => navigate(PATH.MAIN)}>
                            <Logo/>
                        </div>
                    </div>
                    <div className={s.header_right}>


                        <div>
                            <NavLink to={`${PATH.AUCTIONS}/up`}>Аукционы</NavLink>
                            <NavLink to={PATH.BLOGS}>Блог</NavLink>
                            <NavLink to={PATH.SUPPORT}>Помощь</NavLink>
                        </div>

                        {!isAuth && <div className={s.auth_btns}>
                            <Button className={s.login_btn} title={'Вход'} padding={'12px 24px'}
                                    onClick={() => authModal.actionModal(true)}/>

                            <Button className={s.reg_btn} title={'Регистрация'} padding={'12px 24px'}
                                    onClick={() => {
                                        searchParams.set('registration', 'open')
                                        setSearchParams(searchParams)
                                        setTimeout(() => {
                                            searchParams.delete('registration')
                                            setSearchParams(searchParams)
                                        }, 100)
                                    }}/>

                        </div>}

                        {isAuth && <div className={s.loginned}>

                            <div className={s.icons_box}>
                                <div className={s.active_lots}>
                                    <Tooltip title="Активные аукционы">
                                        <div className={s.loggind_icon} onClick={(e) => {
                                            e.stopPropagation()
                                            setOpenOpenActiveLots(!openActiveLots)
                                            setOpenSelect(false)
                                        }}>
                                            <div className={s.circle_count}>{activeLots?.results?.length || 0}</div>
                                            <Gavel/>
                                        </div>
                                    </Tooltip>
                                    {openActiveLots &&
                                        <ActiveLots closeActiveLots={() => setOpenOpenActiveLots(false)}/>}
                                </div>


                                <Tooltip title="Избранное">
                                    <div className={s.loggind_icon}
                                         onClick={() => navigate(PATH.ACCOUNT + '/' + PATH.FAVORITES)}>
                                        <div className={s.circle_count}>{favorites?.count || 0}</div>
                                        <Like/>
                                    </div>
                                </Tooltip>

                            </div>

                            <div className={s.avatar_box_select}>

                                <AnimatedHeaderCircle free_bid={free_bid} setOpenOpenActiveLots={setOpenOpenActiveLots}
                                                      setOpenSelect={setOpenSelect} openSelect={openSelect}>
                                    <Avatar src={user?.customer_profile?.avatar}
                                            sx={{borderRadius: '50%', height: '100%', width: '100%'}}
                                            alt="avatar"/>
                                </AnimatedHeaderCircle>

                                {(openSelect) && <div className={s.avatar_box_select_content}>
                                    <div onClick={(e) => {
                                        e.stopPropagation()
                                    }} className={s.avatar_box_select_content_header}>
                                        <div className={s.avatar_box_select_content_header_top}>
                                            <p className={s.user_name}>{`${user?.customer_profile?.nickname || ''}`}</p>
                                            {user?.email_verified ?
                                                <Tooltip title={''}>
                                                    <Verified/>
                                                </Tooltip>
                                                :
                                                <Tooltip title={'Требуются действия'}>
                                                    <NewReleasesIcon color={'warning'}/>
                                                </Tooltip>}
                                        </div>

                                        {free_bid &&
                                            <p className={s.info_free_bid}>{`Бесплатный период до ${moment(user?.customer_profile?.free_bid_period_ends_at)?.format('DD.MM.YYYY')}`}</p>}
                                    </div>

                                    <div className={s.content}>
                                        {path.map((el) => <NavLink className={s.path} key={el.path}
                                                                   to={el.path}>{el.title}</NavLink>)}
                                        <p className={s.path} onClick={handleLogout} style={{
                                            paddingLeft: 0
                                        }}>Выйти</p>
                                    </div>
                                </div>}
                            </div>

                        </div>}
                    </div>

                    <div className={s.login_box_mobile}>

                        {isAuth ?
                            (<>{openRightMenu ?
                                <div className={s.burger} onClick={toggleDrawerRight(!openRightMenu)}>
                                    <Close/>
                                </div>
                                :
                                // toggleDrawerRight(!openRightMenu)
                                <AnimatedHeaderCircle type={2} onClick={toggleDrawerRight(!openRightMenu)}
                                                      free_bid={free_bid} setOpenOpenActiveLots={setOpenOpenActiveLots}
                                                      setOpenSelect={setOpenSelect} openSelect={openSelect}>
                                    <Avatar src={user?.customer_profile?.avatar}
                                            sx={{borderRadius: '50%', height: '100%', width: '100%'}}
                                            alt="avatar"/>
                                </AnimatedHeaderCircle>}</>)
                            :
                            (<div className={classNames(s.burger, s.burger_remember)}
                                  onClick={() => authModal.actionModal(true)}>
                                <NavigateProfile/>
                            </div>)
                        }
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Header;
