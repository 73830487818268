import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQueryWithReauth} from "../api/baseQuery";
import Cookies from "js-cookie";

export const myLotsApi = createApi({
    reducerPath: 'myLotsApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['lots'],
    endpoints: (build) => ({
        getMyLots: build.query({
            query: () => ({
                url: `lots/customer/secured-lots/`,
                method: 'GET',
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
            transformResponse(baseQueryReturnValue, meta, arg) {
                console.log(baseQueryReturnValue)
                return {
                    ...baseQueryReturnValue,
                    results: baseQueryReturnValue?.results?.map((el) => ({
                        ...el,
                        highest_bid: el?.highest_bid_amount,
                        user_highest_bid: el?.user_highest_bid_amount,
                    }))
                }
            },
            providesTags: ['lots']
        }),
        getLot: build.query({
            query: (id) => ({
                url: `lots/customer/secured-lots/${id}/`,
                method: 'GET',
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
        }),
        payLot: build.mutation({
            query: (payload) => ({
                url: `lots/customer/secured-lots/pay/`,
                method: 'POST',
                body: payload,
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
        }),
        getAvilibleMethods: build.query({
            query: () => ({
                url: `delivery/customer/methods/`,
                method: 'OPTIONS',
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
        }),
        rejectLot: build.mutation({
            query: (payload) => ({
                url: `lots/customer/secured-lots/reject-to-buy/`,
                method: 'POST',
                body: payload,
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
            invalidatesTags: (res, error, erg) => error ? [] : ['lots']
        }),
    })
})

export const {
    useGetMyLotsQuery,
    usePayLotMutation,
    useRejectLotMutation,
    useGetLotQuery,
    useGetAvilibleMethodsQuery,
} = myLotsApi;
