import React from 'react';
import s from './styles.module.css'
import Container from "../../../../components/container";
import Button from "../../../../common/ui_kit/button";
import AccordionInfo from "./accordion";
import {NavLink, useNavigate} from "react-router-dom";
import {PATH} from "../../../../constants/path";

const data = [
    {sub_title: 1, title: 'Что такое ITEMBUY?'},
    {sub_title: 2, title: 'Можно ли действительно купить товар на ITEMBUY на 50% дешевле?'},
    {sub_title: 3, title: 'Почему цены такие низкие и на чем зарабатывает платформа?'},
    {sub_title: 4, title: 'В чем выгода для покупателей и продавцов?'},
    {sub_title: 5, title: 'Товары на ITEMBUY оригинальные и новые? Есть ли на них гарантия?'},
    {sub_title: 6, title: 'Что необходимо для участие в аукционе?'},
]

const Page4 = ({handleReg, isAuth}) => {
    const navigate = useNavigate()

    const components = {
        1: <p className={s.title_acc}><b>ITEMBUY</b> – это маркетплейс-аукцион, где цена на товары формируется самими
            покупателями. Участвуя в аукционах, вы можете приобрести новые, оригинальные товары по цене, которую
            считаете справедливой.</p>,
        2: <p className={s.title_acc}>Да, это возможно! Цена на товар формируется в процессе открытого аукциона, где
            конечная стоимость зависит от активности участников, времени суток, дня недели и ликвидности товара. Иногда
            удается купить товар <b>более чем на 50% дешевле</b>, чем в обычных магазинах.
        </p>,

        3: <p className={s.title_acc}><b>Цены на АЙТЕМБАЙ значительно ниже рыночных.</b> Платформа взимает небольшую
            комиссию
            за каждую ставку, и эти средства направляются в компенсационный фонд. Этот фонд покрывает разницу между
            закупочной ценой у поставщика и той, по которой товар был продан на аукционе. Благодаря продуманной системе
            компенсационных фондов и уникальным алгоритмам, ITEMBUY обеспечивает баланс между выгодой для покупателя и
            доходом для продавца. Оставшаяся часть комиссии является доходом платформы.
        </p>,

        4: <p className={s.title_acc}>Покупатели получают возможность установить свою собственную цену на товар,
            участвуя в аукционе. Это может привести к <b>экономии более 50%</b> от стандартной розничной стоимости.
            Продавцы, в
            свою очередь, получают гарантированный доход за свои товары, даже если итоговая цена оказывается ниже
            рыночной. Такой подход создает выигрышные условия для всех участников платформы.
        </p>,

        5: <p className={s.title_acc}>Все товары на АЙТЕМБАЙ – <b>новые и оригинальные</b>, от проверенных поставщиков.
            Мы
            предоставляем 14-дневную гарантию на возврат и обмен в соответствии с законодательством. Это значит, что вы
            можете вернуть или обменять товар, если он не оправдал ваших ожиданий. Особые условия возврата касаются
            только товаров включенных в перечень «технически сложных товаров», в соответствии с законодательством.</p>,
        6: <p className={s.title_acc}>Чтобы участвовать в аукционах, необходимо <span onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            handleReg()
        }}
        >зарегистрироваться</span>. Это не
            сложно и занимает не более 5 минут</p>,
    }
    return (
        <Container>
            <div className={s.content}>
                <h4 className={s.title}>
                    Часто-задаваемые <br/>
                    вопросы
                </h4>

                <div className={s.box}>
                    {(isAuth ? data?.filter((f) => f.sub_title !== 6) : data)?.map((el, i) => {
                        return <AccordionInfo label={el.title} key={el?.sub_title}>
                            {components[el.sub_title]}
                        </AccordionInfo>
                    })}

                </div>

                <div className={s.btn}>
                    <Button onClick={() => navigate(`${PATH.AUCTIONS}/up`)}>Перейти к аукционам</Button>
                </div>
            </div>
        </Container>
    );
};

export default Page4;