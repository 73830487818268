import {createApi} from '@reduxjs/toolkit/query/react'
import moment from "moment-timezone";
import {baseQueryWithReauth} from "../api/baseQuery";
import Cookies from "js-cookie";

export function generateColor(letter) {
    // Преобразуем букву в число, чтобы использовать его в качестве параметра для генерации цвета
    const num = letter.charCodeAt(0);
    // Генерируем цвет, используя значения ASCII-кодов символа
    const color = "#" + ((num * 123456) & 0xffffff).toString(16);
    return color;
}

export const auctionsApi = createApi({
    reducerPath: 'auctionsApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Auctions', 'Auction'],
    endpoints: (build) => ({
        getAuctionsUp: build.query({
            query: ({
                        page_size = 12,
                        cat = '',
                        subcat = '',
                        page = 1,
                        state = [],
                        brand = [],
                        ord = ''
                    }) => ({
                url: `auctions/`,
                params: {
                    page_size: page_size,
                    cat: cat,
                    subcat: subcat,
                    page: page,
                    state: state,
                    brand: brand,
                    ord: ord,
                },
                method: 'GET',
            }),
            transformResponse(baseQueryReturnValue, meta, arg) {
                return {
                    ...baseQueryReturnValue, results: baseQueryReturnValue?.results?.map((el) => {
                        const time_for_bid = el?.time_for_bid?.split(':')

                        return {
                            ...el, default_timer: el.state === 'run' ?
                                (el?.highest_bid_date ?
                                    moment.tz(el?.highest_bid_date, 'Europe/Moscow').add(time_for_bid[0], 'hours').add(time_for_bid[1], 'minutes').add(time_for_bid[2], 'second').format()

                                    : moment.tz(el?.start_date, 'Europe/Moscow').add(time_for_bid[0], 'hours').add(time_for_bid[1], 'minutes').add(time_for_bid[2], 'second').format())
                                : moment().format()
                        }
                    })
                }
            },
            providesTags: ['Auctions'],
        }),
        getAuction: build.query({
            query: (id) => ({
                url: `auctions/${id}/`,
                method: 'GET',
            }),
            transformResponse(baseQueryReturnValue, meta, arg) {
                const time_for_bid = baseQueryReturnValue?.time_for_bid?.split(':')?.map((el) => +el)

                if (baseQueryReturnValue?.state === 'end') {
                    return baseQueryReturnValue
                } else {
                    return {
                        ...baseQueryReturnValue, default_timer: (baseQueryReturnValue?.state === 'run' &&
                                (baseQueryReturnValue?.highest_bid_date ?
                                    moment.tz(baseQueryReturnValue?.highest_bid_date, 'Europe/Moscow').add(time_for_bid[0], 'hours').add(time_for_bid[1], 'minutes').add(time_for_bid[2], 'second').format()
                                    : moment.tz(baseQueryReturnValue?.start_date, 'Europe/Moscow').add(time_for_bid[0], 'hours').add(time_for_bid[1], 'minutes').add(time_for_bid[2], 'second').format())) ||
                            (baseQueryReturnValue.state === 'pub' && moment.tz(baseQueryReturnValue?.start_date, 'Europe/Moscow'))?.format() || moment().format()
                    }
                }


            },
            providesTags: ['Auction'],
        }),
        getBidAuction: build.query
        ({
            query: ({id, page = 1}) => ({
                url: `bids/?auction_id=${id}&page_size=12&page=${page}`,
                method: 'GET',
            }),
            transformResponse(baseQueryReturnValue, meta, arg, a, b) {
                const reverseData = baseQueryReturnValue?.results?.reverse()

                return {
                    ...baseQueryReturnValue,
                    results: reverseData?.map((user) => ({
                        ...user,
                        color: generateColor(user.user[0])
                    }))
                }
            },
            providesTags: ['Auction'],
        }),
        getAuctionsUpFinish: build.query({
            query: ({
                        page_size = 12,
                        subcat = '',
                        page = 1,
                        state = [],
                        brand = [],
                        ord = ''
                    }) => ({
                url: `auctions/`,
                params: {
                    page_size: page_size,
                    cat: '',
                    subcat: subcat,
                    page: page,
                    state: ['end'],
                    brand: brand,
                    ord: ord,
                },
                method: 'GET',
            }),
            providesTags: ['Auctions'],
        }),
        getAuctionsUpFinishSwiper: build.mutation({
            query: ({
                        page_size = 12,
                        subcat = '',
                        page = 1,
                        brand = [],
                        ord = ''
                    }) => ({
                url: `auctions/`,
                params: {
                    page_size: page_size,
                    cat: '',
                    subcat: subcat,
                    page: page,
                    state: ['end'],
                    brand: brand,
                    ord: ord,
                },
                method: 'GET',
            }),
            providesTags: ['Auctions'],
        }),
        getAuctionsUpRun: build.mutation({
            query: ({
                        page_size = 12,
                        subcat = '',
                        page = 1,
                        brand = [],
                        ord = ''
                    }) => ({
                url: `auctions/`,
                params: {
                    page_size: page_size,
                    cat: '',
                    subcat: subcat,
                    page: page,
                    state: ['run'],
                    brand: brand,
                    ord: ord,
                },
                method: 'GET',
            }),
            providesTags: ['Auctions'],
        }),
        setAuctionReview: build.mutation({
            query: (payload) => ({
                url: `auction-review/`,
                body: payload,
                method: 'POST',
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),

            // providesTags: ['Auctions'],
        }),
    }),
})

export const {
    useGetAuctionsUpQuery,
    useGetAuctionQuery,
    useGetBidAuctionQuery,
    useGetAuctionsUpFinishQuery,
    useGetAuctionsUpFinishSwiperMutation,
    useGetAuctionsUpRunMutation,
    useSetAuctionReviewMutation,
} = auctionsApi;
