import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import Container from "../container";
import {NavLink} from "react-router-dom";
import Search from "../../assets/svg/search";
import {PATH} from "../../constants/path";
import InputTitle from "../../common/input_title";
import {ReactComponent as Close} from "../../assets/svg/close.svg";
import {useSelector} from "react-redux";
import {selectCategory} from "../../redux/slice/category_subcategory.slice";

const Navigate = () => {
    const {category: category_data} = useSelector(selectCategory)

    const [showInput, setShowInput] = useState(false)

    useEffect(() => {
        window.addEventListener('click', (e) => {
            setShowInput(false)
        })

        return () => {
            window.removeEventListener('click', (e) => {
                setShowInput(false)
            })
        }
    }, [])

    return (
        <div className={s.navigate_container}>
            <Container>
                <div className={s.navigate}>
                    {!showInput && <div className={s.navigate_items}>
                        {category_data?.results?.map((el) => <NavLink key={el.slug}
                                                                      to={`${PATH.AUCTIONS}/up/${el.slug.toLowerCase()}`}>{el.title}</NavLink>)}

                    </div>}
                    {showInput && <div className={s.navigate_input} onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}>
                        <div className={s.serach_input}>
                            <Search/>
                        </div>
                        <InputTitle onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                setShowInput(false)
                            }
                        }} className={s.input} isNeedTitle={false} placeholder={'Строка поиска ещё не активна'}/>
                    </div>}
                    <div className={s.search} onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setShowInput(!showInput)
                    }}>
                        {!showInput ? <Search/> : <Close/>}
                    </div>
                </div>

            </Container>
        </div>
    );
};

export default Navigate;
