import React, {useContext, useEffect, useState} from 'react';
import s from "../styles.module.css";
import StatusTitle from "../status_title";
import {Avatar, IconButton, Tooltip, useMediaQuery} from "@mui/material";
import noImage from '../../../../assets/images/no_image.jpg'
import {commisionCircle, spaceBtwHundreds} from "../../../../helper/spaceBetweenSum";
import {ReactComponent as AuctionUpIcon} from "../../../../assets/svg/auction_up.svg";
import classNames from "classnames";
import {ReactComponent as Arrow} from "../../../../assets/svg/arrow_next_price.svg";
import {NavLink} from "react-router-dom";
import moment from "moment";
import Button from "../../../../common/ui_kit/button";
import {useDispatch, useSelector} from "react-redux";
import {selectCategory} from "../../../../redux/slice/category_subcategory.slice";
import {PATH} from "../../../../constants/path";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {toast} from "react-toastify";
import {AuthContext} from "../../../../App";
import {useSetFavoriteMutation} from "../../../../redux/favority.service";
import {selectAuctions, setAuctions} from "../../../../redux/slice/auctions.slice";
import {motion} from "framer-motion";

const customButtonStyle = {
    height: '100%',
    width: '100%',
};

const ItemBox = ({
                     timer,
                     startDateTime,
                     highest_bid_amount,
                     time_for_bid,
                     base_price,
                     bid_price_percent,
                     min_price_increment,
                     start_date,
                     follow,
                     min_bid_price_amount,
                     end_date,
                     product,
                     hero_image,
                     state,
                     id,
                 }) => {
    const dispatch = useDispatch()
    const {auctions} = useSelector(selectAuctions)

    const [setFavorite, {isLoading}] = useSetFavoriteMutation()
    const [sumRun, setSunRun] = useState(highest_bid_amount ? highest_bid_amount : base_price)
    const [sumRunNext, setSunRunNext] = useState(highest_bid_amount ? +highest_bid_amount + +min_price_increment : +base_price + +min_price_increment)
    const [animateText, setAnimateText] = useState(false);
    const authModal = useContext(AuthContext)

    const {category: category_data} = useSelector(selectCategory)


    const query_700 = useMediaQuery('(max-width:780px)');
    const duration_completed = moment.duration(moment(end_date).diff(moment(start_date))) ? moment.duration(moment(end_date).diff(moment(start_date))) : null;

    const duration_completed_obj = {
        hh: `${duration_completed.hours() >= 10 ? duration_completed.hours() : `0${duration_completed.hours()}`}`,
        mm: `${duration_completed.minutes() >= 10 ? duration_completed.minutes() : `0${duration_completed.minutes()}`}`,
        ss: `${duration_completed.seconds() >= 10 ? duration_completed.seconds() : `0${duration_completed.seconds()}`}`,
    }


    const category_slag = category_data?.results?.find((f) => f.id === product?.category)
    const sub_category_slag = category_slag?.subcategory_set?.find((f) => f.id === product?.subcategory)

    const handleClick = () => {
        if (authModal?.isAuth) {
            setFavorite(id)
                .unwrap()
                .then((res) => {
                    dispatch(setAuctions({
                        auctions: {
                            ...auctions,
                            results: auctions?.results?.map((el) => +el.id === +id ? ({
                                ...el,
                                follow: Boolean(res?.detail?.includes('Added'))
                            }) : el)
                        }
                    }))
                    if (res?.detail?.includes('Added')) {
                        toast.success('Аукцион добавлен в избранное', {autoClose: 1000})
                    } else {
                        toast.warning('Аукцион удален из избранного', {autoClose: 1000})
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        } else {
            authModal.actionModal(true, 'Для данного действия необходима авторизация')
        }
    }

    useEffect(() => {
        if (state === 'run') {

            const animationDuration = 100; // Длительность анимации в миллисекундах

            const startTime = Date.now();
            const endTime = startTime + animationDuration;

            const updatePrice = () => {
                const now = Date.now();
                const progress = Math.min(1, (now - startTime) / animationDuration);
                const currentPrice = sumRun + ((highest_bid_amount ? highest_bid_amount : base_price) - sumRun) * progress;
                const currentPriceNext = sumRunNext + ((highest_bid_amount ? +highest_bid_amount + +min_price_increment : +base_price + +min_price_increment) - sumRunNext) * progress;

                setSunRun(+currentPrice ? +currentPrice?.toFixed(0) : 0);
                setSunRunNext(+currentPriceNext ? +currentPriceNext?.toFixed(0) : 0);

                if (now < endTime) {
                    requestAnimationFrame(updatePrice);
                } else {
                    setAnimateText(false)
                }
            };
            setAnimateText(true)
            updatePrice();
        }
    }, [state, highest_bid_amount, sumRun])

    return (
        <>
            <div className={s.media_box}>
                {query_700 ? null : <StatusTitle
                    status_item={state}
                    startDateTime={startDateTime}
                />}

                <div className={s.header_btns_box}
                     style={{gridTemplateColumns: '1fr'}}>
                    <Tooltip title={follow ? 'Удалить из избранного' : 'Добавить в избранное'}>
                        <IconButton
                            sx={{
                                ...customButtonStyle,
                            }}
                            disabled={isLoading} onClick={handleClick}
                        >
                            {follow ?
                                <FavoriteIcon sx={{color: '#5654D4'}}/> :
                                <FavoriteBorderIcon sx={{color: '#91969F'}}/>}
                        </IconButton>
                    </Tooltip>
                </div>

                <NavLink to={`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${id}`}
                         style={{width: query_700 ? 'fit-content' : '100%', height: '100%'}}>
                    <Avatar
                        sx={{
                            height: '100%',
                            width: query_700 ? 'fit-content' : '100%',
                            borderRadius: query_700 ? '10px' : '12px',
                            overflow: 'hidden',
                        }}
                        src={product?.hero_image || noImage}
                    />
                </NavLink>
                <div className={classNames(s.title_mobile)}>
                    <NavLink to={`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${id}`}
                             style={{width: '100%', height: '100%'}}>
                        <Tooltip title={product?.title}>
                            <span>{product?.title}</span>
                        </Tooltip>
                    </NavLink>
                </div>
            </div>

            <div className={s.content_box}>

                <div>
                    <div className={s.title}>
                        <Tooltip title={product?.title}>
                            <NavLink to={`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${id}`}
                                     style={{width: '100%'}}>
                                <span>{product?.title}</span>
                            </NavLink>
                        </Tooltip>
                    </div>

                    {!query_700 ? null : <StatusTitle
                        status_item={state}
                        startDateTime={startDateTime}
                    />}

                    {state === 'end' && <div className={s.content_completed}>
                        <div className={s.content_bidding_published}>
                            <div className={s.content_item}>
                                <p className={s.content_item_title}>Время торгов</p>
                                <p className={s.content_item_duration}>
                                    {`${duration_completed_obj.hh}:${duration_completed_obj.mm}:${duration_completed_obj.ss}`}
                                </p>
                            </div>
                            <div className={s.content_item}>
                                <p className={s.content_item_title}>Комиссия, от</p>
                                <p className={s.content_item_duration}>{+bid_price_percent === 0 ? 'Бесплатно' : `${spaceBtwHundreds(commisionCircle((+min_price_increment * +bid_price_percent) / 100))} ₽`} </p>
                            </div>
                        </div>
                        <div className={s.final_price_item}>
                            <p className={s.content_item_title}>Цена выкупа</p>
                            <div className={s.price_box}>
                                <p className={s.price_box_title} style={{
                                    fontSize: highest_bid_amount === null && '14px'
                                }}>{highest_bid_amount !== null ? `${spaceBtwHundreds(highest_bid_amount)} ₽` : 'Аукцион не состоялся'}</p>
                                <Tooltip title={'Торги на повышение'}>
                                    <AuctionUpIcon/>
                                </Tooltip>
                            </div>
                        </div>
                    </div>}

                    {(state === 'run' || state === 'pub') &&
                        <div className={s.content_completed}>
                            <div className={s.content_bidding_published}>
                                <div className={s.content_item}>
                                    <p className={s.content_item_title}>Время на ставку</p>
                                    <motion.p
                                        initial={(state === 'run' && timer?.isRunning && timer?.totalSeconds < 11) ? {
                                            scale: 1,
                                            translateZ: 0
                                        } : {}}
                                        animate={(state === 'run' && timer?.isRunning && timer?.totalSeconds < 11) ? {
                                            scale: [1, 1.028, 1],
                                            translateZ: 0
                                        } : {}}
                                        transition={(state === 'run' && timer?.isRunning && timer?.totalSeconds < 11) ? {
                                            duration: 1,
                                            ease: "easeInOut",
                                            repeat: Infinity,
                                            delay: 0
                                        } : {}}
                                        style={(state === 'run' && timer?.isRunning && timer?.totalSeconds < 11) ? {color: '#f85151'} : {}}
                                        className={s.content_item_duration}>
                                        {state === 'run' && (`${timer.dd === 0 ? '' : `${timer.hh}:`}${timer.hh}:${timer.mm}:${timer.ss}`)}
                                        {state === 'pub' && time_for_bid}
                                    </motion.p>
                                </div>
                                <div className={s.content_item}>
                                    <p className={s.content_item_title}>Комиссия, от</p>
                                    <p className={s.content_item_duration}>{+bid_price_percent === 0 ? 'Бесплатно' : `${spaceBtwHundreds(commisionCircle((+min_price_increment * +bid_price_percent) / 100))} ₽`} </p>
                                </div>
                            </div>
                            <div className={s.final_price_item}>
                                <p className={s.content_item_title}>{
                                    (state === 'run' && 'Текущая цена') ||
                                    (state === 'pub' && 'Начальная цена')
                                }</p>
                                {state === 'pub' && <div className={s.price_box}>
                                    <p className={s.price_box_title}>{spaceBtwHundreds(base_price)} ₽</p>
                                    <Tooltip title={'Торги на повышение'}>
                                        <AuctionUpIcon/>
                                    </Tooltip>
                                </div>}
                                {state === 'run' &&
                                    <div className={classNames(s.price_box, s.price_box_bidding)}>
                                        <p className={classNames(s.price_box_title, animateText && s.price_box_title_animation)}>{spaceBtwHundreds(sumRun)} ₽</p>
                                        <Arrow/>
                                        <p className={classNames(s.price_box_title, animateText && s.price_box_title_animation)}>{spaceBtwHundreds(sumRunNext)} ₽</p>
                                    </div>}
                            </div>
                        </div>}
                </div>

                <div className={s.button_retail}>
                    <div className={s.retail}>
                        {`Розничная цена — ${spaceBtwHundreds(product?.retail_price)} ₽`}
                    </div>
                    <NavLink to={`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${id}`}>
                        <Button className={s.btn} onClick={() => {

                        }}>
                            Перейти к лоту
                        </Button>
                    </NavLink>
                </div>

            </div>
        </>
    );
};

export default ItemBox;
