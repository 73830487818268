import {createSlice} from "@reduxjs/toolkit";
import {auctionsApi} from "../auctions.service";

export const auctionsSlice = createSlice({
    name: 'auctions',
    initialState: {
        auctions: null,
        auctionsFinish: null,
        auctionsRoom: null,
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            auctionsApi.endpoints.getAuctionsUp.matchFulfilled,
            (state, {payload}) => {
                state.auctions = payload
            },
        )
        builder.addMatcher(
            auctionsApi.endpoints.getAuction.matchFulfilled,
            (state, {payload}) => {
                state.auctionsRoom = payload
            },
        )
        builder.addMatcher(
            auctionsApi.endpoints.getAuctionsUpFinish.matchFulfilled,
            (state, {payload}) => {
                state.auctionsFinish = payload
            },
        )
    },
    reducers: {
        setAuctions: (state, action) => {
            state.auctions = action.payload.auctions
        },
        setAuctionsRoom: (state, action) => {
            state.auctionsRoom = action.payload.auctionsRoom
        },
        setAuctionsFinish: (state, action) => {
            state.auctionsFinish = action.payload.auctionsFinish
        },
    }
})

export const {setAuctions, setAuctionsRoom, setAuctionsFinish} = auctionsSlice.actions

export const selectAuctions = (state) => state.auctions;

export default auctionsSlice.reducer
